import { Perf, PerfCommonData, EXTER_PREF, Options, REPORT_TYPE } from '../index.interface'
import { reportFMP } from '../util/fmp'
import { getPerfFcp } from '../util/fcp'
import getTTI from '../util/tti'

const _win = window
const _perf: EXTER_PREF = { fmp: -9999, fcp: -9999, tti: -9999 }
const PERF_KEY = { fmp: 'useFMP', fcp: 'useFCP', tti: 'useTTI' }
const INTERVAL_TIME = 600

let timer

function isFinishToReportPerf(options: Options) {
  return Object.keys(_perf).length
    ? Object.keys(_perf).every((item) => {
        return options[PERF_KEY[item]] ? !!(_perf[item] >= 0 || _perf[item] === -1) : true
      })
    : true
}

const isTimingAPISupport = (): boolean => {
  return !!(_win.performance && _win.performance.timing)
}

export const getCommonData = (): PerfCommonData => {
  // @ts-ignore
  const sr = `${screen.width}x${screen.height}`
  const dpr = (_win.devicePixelRatio || 1).toFixed(2)
  const common = {
    sr,
    dpr
  }
  return common
}

const getPerfByTimingAPI = (extraData: any): Perf => {
  const t = _win.performance.timing
  const result: Perf = {
    // 阶段耗时
    dns: t.domainLookupEnd - t.domainLookupStart,
    tcp: t.connectEnd - t.connectStart,
    sslready: t.secureConnectionStart > 0 ? t.connectEnd - t.secureConnectionStart : 0,
    ttfb: t.responseStart - t.requestStart,
    trans: t.responseEnd - t.responseStart,
    domanalysis: t.domInteractive - t.responseEnd,
    resload: t.loadEventStart - t.domContentLoadedEventEnd,
    // 关键性能指标
    firstbyte: t.responseStart - t.domainLookupStart,
    fpt: t.responseEnd - t.fetchStart,
    // tti: t.domContentLoadedEventEnd - t.fetchStart,
    domready: t.domContentLoadedEventEnd - t.fetchStart,
    loaded: t.loadEventEnd - t.fetchStart,
    ...extraData
  }
  return result
}

export const watchPerfData = async (options: Options) => {
  if (options.useFCP) {
    getPerfFcp()
      .then((fcp) => {
        _perf.fcp = fcp
      })
      .catch(() => {
        delete _perf.fcp
      })
  }
  if (options.useFMP) {
    reportFMP()
      .then((res) => {
        _perf.fmp = res
      })
      .catch(() => {
        delete _perf.fmp
      })
  }

  if (options.useTTI) {
    getTTI()
      .then((tti) => {
        tti && (_perf.tti = tti)
      })
      .catch(() => {
        delete _perf.tti
      })
  }
}

export const clearTimer = () => {
  timer && clearTimeout(timer)
}

export const reportPerf = (options: Options, cb: Function) => {
  if (isTimingAPISupport()) {
    if (isFinishToReportPerf(options)) {
      cb({
        type: REPORT_TYPE.Perf,
        data: {
          ...getPerfByTimingAPI(_perf),
          ..._perf
        }
      })
    } else {
      timer = setTimeout(() => {
        timer = null
        reportPerf(options, cb)
      }, INTERVAL_TIME)
    }
  }
}
