/**
 * 重写bridge sdk的方法，支持bridge方法调用次数统计，调用成功和失败次数上报，
 * 针对特殊需要统计回调值的bridge，自动上报成功的值
 */
import { v4 as uuid4 } from 'uuid'
import { BridgeTypeEnum, REPORT_TYPE, bridgeDetailParams } from '../index.interface'
/**
 * 鉴于每个bridge call的方法返回的结构不一致，这里只重点检测常用的bridge
 */

const bridgeCallFunctionMap = {
  popWebView: {
    isFail: (res: any) => {
      return res.status === 0
    }
  },
  navigate: {
    isFail: (res: any) => {
      return res.error === 0
    }
  },
  saveMediaToDeviceAlbum: {
    isFail: (res: any) => {
      return res.error === 1
    }
  },
  checkAppPermission: {
    isFail: (res: any) => {
      return res.status !== 1
    }
  },
  requestAppPermission: {
    isFail: (res: any) => {
      return res.status === 0
    },
    key: 'status'
  },
  getDeviceInfo: {
    isFail: (res: any) => {
      return res.error === 1
    }
  },
  getAppInfo: {
    isFail: (res: any) => {
      return !res.deviceID
    }
  },
  getLocation: {
    isFail: (res: any) => {
      return res.status === 0
    },
    key: 'status'
  },
  getTongdunBlackbox: {
    isFail: (res: any) => {
      return res.error === 1
    }
  },
  openICCamera2: {
    isFail: (res: any) => {
      return res.error === 1
    }
  },
  openICCamera3: {
    isFail: (res: any) => {
      return res.error === 1
    },
    key: 'error'
  },
  convertToBase64: {
    isFail: (res: any) => {
      return res.error === 2
    }
  },
  pickImage: {
    isFail: (res: any) => {
      return res.error === 1
    },
    key: 'error'
  },
  addLocalNotification: {
    isFail: (res: any) => {
      return res.error === 1
    }
  },
  requestAuthCodeFromCoreAuth: {
    isFail: (res: any) => {
      return res.errorCode !== 0
    }
  },
  getNetworkInfo: {
    isFail: (res: any) => {
      return !res.cellularType && !res.networkType
    }
  },
  startListenForOtp: {
    isFail: (res: any) => {
      return res.error === 1
    }
  },
  load: {
    isFail: (res: any) => {
      return res.status === 0
    }
  },
  save: {
    isFail: (res: any) => {
      return res.status === 0
    }
  },
  pickSystemContact: {
    isFail: (res: any) => {
      return res.error === 1
    },
    key: 'error'
  },
  uploadDeviceInfo: {
    isFail: (res: any) => {
      return res.error === 1
    }
  }
}

// 单独上报统计bridge的结果的bridgename，比如getLocation，不同的值具有统计意义
const bridgeNameResultKeys = [
  'getLocation',
  'openICCamera3',
  'pickSystemContact',
  'pickImage',
  'requestAppPermission'
]

export default class CustomBrigde {
  updateEventList // 记录bridge调用情况
  cacheBridge // 缓存bridge
  deleteBridge // 删除bridge
  getCacheBridge // 获取bridge

  constructor({ updateEventList, cacheBridge, deleteBridge, getCacheBridge }) {
    this.updateEventList = updateEventList
    this.cacheBridge = cacheBridge
    this.deleteBridge = deleteBridge
    this.getCacheBridge = getCacheBridge
    this.init()
  }

  init() {
    this.reWriteBridgeCallHandler()
  }

  reWriteBridgeCallHandler() {
    const _this = this
    const _func = window.bridgeCallHandler
    if (_func) {
      window.bridgeCallHandler = (...params) => {
        /**
         *  bridgeCallHandler有action, params, callback三个参数
         */

        const [name, options, resolveFun] = params

        let duration = Date.now() // 耗时统计

        if (name === 'getAppInfo' && this.isGetAppInfoCalledBySelf(resolveFun)) {
          _func(...params)
          return
        }
        const uuid = uuid4()

        // 缓存bridge调用
        this.cacheBridge(uuid, {
          type: REPORT_TYPE.Bridge,
          data: {
            name,
            type: BridgeTypeEnum.total
          }
        })
        _func.call(window, name, options, (...res) => {
          // 如果functionname不在bridgeCallFunctionMap里面，则不上报调用成功与否
          const isExist = Object.keys(bridgeCallFunctionMap).includes(name)
          const isInSuccessKey = bridgeNameResultKeys.includes(name)
          duration = Date.now() - duration
          // 上报调用成功与否
          if (isExist) {
            const isFail = bridgeCallFunctionMap[name].isFail(...res)
            // 上报调用成功的具体值
            if (isInSuccessKey && !isFail) {
              const data = res[0] || {}
              _this.reportBridgeDetail(
                {
                  name,
                  isSuccess: !isFail,
                  duration,
                  successResult: data[bridgeCallFunctionMap[name].key]
                },
                uuid
              )
            } else {
              _this.reportBridgeDetail(
                {
                  name,
                  isSuccess: !isFail,
                  duration
                },
                uuid
              )
            }
          }
          resolveFun(...res)
        })
      }
    }
  }

  // 判断getAppInfo的回调函数是不是和bridge sdk调用的回调函数一致
  isGetAppInfoCalledBySelf(resolveFun) {
    return resolveFun.toString().includes('BI_TRACKING_PROTO_GENERAL_INFO.deviceid')
  }

  reportBridge(bridgeName: string) {
    this.updateEventList({
      type: REPORT_TYPE.Bridge,
      data: {
        name: bridgeName,
        type: BridgeTypeEnum.total
      }
    })
  }

  reportBridgeDetail(params: bridgeDetailParams, uuid) {
    this.updateEventList({
      type: REPORT_TYPE.Bridge,
      data: {
        ...params,
        type: BridgeTypeEnum.detail
      }
    })
    this.updateEventList({
      ...this.getCacheBridge(uuid)
    })
    this.deleteBridge(uuid)
  }
}
