import MutationObserver from 'mutation-observer'
import { addEvent } from './common'

const FIRST_CONTENTFUL_PAINT = 'first-contentful-paint'
const FCP_NODE_NAME = ['CANVAS', 'IMG', 'SVG']
const FCP_NODE_TYPE = 3
const START_TIME = performance.timing.fetchStart || Date.now()
const CHECKT_TIME = 500
const MAX_CHECKTIME = 10000

class Fcp {
  observer: any = null
  _timer: any = null
  _fcp: number = -1

  async getPerfFcp() {
    addEvent(window, 'beforeunload', () => {
      this.observer && this.observer.disconnect()
      this._timer && clearTimeout(this._timer)
    })
    if (window.PerformanceObserver) {
      this._fcp = await this.performanceObserver()
    } else {
      this._fcp = await this.startMutationObserver()
    }
    return this._fcp
  }

  startMutationObserver(): Promise<number> {
    return new Promise((resolve) => {
      this.observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.addedNodes.length && this._fcp === -1) {
            const node = mutation.addedNodes[0]
            this._fcp = this.deepSearchNode(node)
            if (this._fcp !== -1) {
              resolve(this._fcp)
              this.observer.disconnect()
            }
          }
        })
      })
      this.observer.observe(document.body, {
        childList: true,
        subtree: true
      })
      this.endToObserve(resolve)
    })
  }

  performanceObserver(): Promise<number> {
    return new Promise(async (resolve) => {
      this.observer = new PerformanceObserver((list) => {
        list.getEntries().forEach((entry) => {
          if (entry.entryType === 'paint' && entry.name === FIRST_CONTENTFUL_PAINT) {
            this._fcp = entry.startTime
            resolve(this._fcp)
          }
        })
      })
      try {
        this.observer.observe({ entryTypes: ['paint'], buffered: true })
        this.endToObserve(resolve)
      } catch (error) {
        /**
         * 有些浏览器不支持这个entrytypes paint这个特性，会抛出错误，这时获取fcp通过其他方式
         */
        this._fcp = await this.startMutationObserver()
        resolve(this._fcp)
      }
    })
  }

  deepSearchNode(node: HTMLElement | Node) {
    let fcp = -1
    if (this.isValid(node)) {
      return Date.now() - START_TIME
    }
    if (node.childNodes.length) {
      for (let i = 0; i < node.childNodes.length; i++) {
        fcp = this.deepSearchNode(node.childNodes[i])
        if (fcp !== -1) return fcp
      }
    }
    return fcp
  }

  isValid(node) {
    const validable = false
    if (node.nodeType === FCP_NODE_TYPE) {
      return node.nodeValue && !/^[\r|\n|↵|\s]*$/.test(node.nodeValue)
    }
    if (node.nodeName === FCP_NODE_NAME[0]) {
      return !this.isCanvasBlank(node)
    }
    if (FCP_NODE_NAME.includes(node.nodeName)) {
      return node.getAttribute('src')
    }
    return validable
  }

  // 如果当页面检测不到fcp,页面加载时间超过最大检测时间，或者当则返回-1
  endToObserveCheck(cb: Function) {
    if (this._fcp !== -1) return
    const now = Date.now()
    const timestamp = now - START_TIME
    if (timestamp > MAX_CHECKTIME) {
      cb(-1)
    } else {
      this._timer = setTimeout(() => {
        this.endToObserveCheck(cb)
      }, CHECKT_TIME)
    }
  }

  endToObserve(resolve: Function) {
    addEvent(window, 'load', () => {
      this.endToObserveCheck(resolve)
    })
  }

  // 判断canvas画布是否有内容,当是空画布的时候，不能算fcp
  isCanvasBlank(canvas) {
    const blank = document.createElement('canvas')
    blank.width = canvas.width
    blank.height = canvas.height

    return canvas.toDataURL() === blank.toDataURL()
  }
}

export const getPerfFcp = () => {
  return new Fcp().getPerfFcp()
}
