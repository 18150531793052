export interface nEvent extends Event {
  arguments?: IArguments
  response?: any
}

export type Options = {
  appId?: string
  url?: string
  autoReportView?: boolean
  autoReportApi?: boolean
  autoReportPerf?: boolean
  autoReportVisit?: boolean
  sampleRate?: number
  parseResponse?: ParseResFn
  urlHelper?: (string | RegExp | HelperObj | Function)[]
  apiHelper?: (string | RegExp | HelperObj | Function)[]
  ignore?: {
    ignoreUrls?: (string | RegExp | IgnoreFn)[]
    ignoreApis?: (string | RegExp | IgnoreFn)[]
    ignoreCode?: (string | number)[]
  }
  useFMP?: boolean
  useFCP?: boolean
  useTTI?: boolean
  autoExpo?: boolean
  bizType: string // 业务类型
  region: string
  isLive?: boolean
  xhr?: (body: any) => any // 支持自定义上报请求
  headers?: Object // 支持自定义请求头
  offlineVersion?: string // 离线包版本
  onlineVersion?: string // 在线包版本
  getUser?: () => string // 获取用户标识
  useWeb?: boolean // 是否使用web上报
  metricConfigs?: MetricConfig[]
}

export type VisitDataType = {
  type: REPORT_TYPE.Visit
  data: {
    url: string
    timestamp: number
    channel?: string
    stay: string | number
  }
}

type ParseResFn = (
  res: any
) => {
  code?: number | string
  msg?: any
  success?: SuccessType
}

enum SuccessType {
  failure = 0,
  success
}

type IgnoreFn = {
  (arg: string): boolean | undefined
}
type HelperObj = {
  rule: string | RegExp
  target: string
}

export type CommonData = {
  appid: string
  uid: string
  sid: string
  url: string
  connecttype: string
  timestamp: number
  sr: string
  dpr: string
}

export type PerfCommonData = {
  sr: string
  dpr: string
}

export type Perf = {
  dns: number // DNS 解析耗时
  tcp: number // TCP 连接耗时,
  sslready: number // SSL 安全连接耗时
  ttfb: number // Time to First Byte（TTFB），网络请求耗时
  trans: number // 数据传输耗时
  domanalysis: number // DOM 解析耗时
  resload: number // 资源加载耗时
  firstbyte: number // 首包时间
  fpt: number // First Paint Time, 首次渲染时间 / 白屏时间
  tti: number // Time to Interact，首次可交互时间
  domready: number // HTML 加载完成时间， 即 DOM Ready 时间
  loaded: number // 页面完全加载时间
  fmp?: number // 主要有效内容渲染
}

export type PV = {
  page: string // 路径名称
  connecttype?: string // 连接类型
}

export type NavPerfEntry = {
  entryType: string
  initiatorType: string
  timeorigin: number
  domready: number
  loaded: number
}

export type ResPerfEntry = {
  name: string
  initiatorType: string
  duration: number
  transferSize: number
  start: number
  dns: number
  tcp: number
  sslready: number
  reqsent: number
  ttfb: number
  download: number
}

export type RES = {
  nav: NavPerfEntry
  res: ResPerfEntry[]
}

export type ReportData = Perf | PV | RES

export type ExtraPerfData = {
  fmp?: number
}

export type EXTER_PREF = {
  [key: string]: number
}

export interface ExpoParams {
  id: string
}

export interface ExpoConfig {
  root?: HTMLElement
  rootMargin?: string
  threshold?: number
}

export interface ExpoReportData {
  id: string
}

export enum REPORT_TYPE {
  Visit = 'visit',
  View = 'pv',
  Api = 'api',
  Click = 'click',
  Impression = 'impression',
  Perf = 'perf',
  Stay = 'stay',
  Bridge = 'bridge',
  Error = 'error',
  Custom = 'custom'
}

export enum ActionType {
  EXPO = 'expo',
  CLICK = 'click'
}

export interface DataExpoAttrParams {
  actionId: string
  params?: ExpoParams
}

export interface EventDataType {
  timestamp?: number
  url?: string
  type: string
  fromUrl?: string
  data: ReportDataType[keyof ReportDataType]
  metricData?: ReportCustomMetricParams[]
}

export interface ReportDataType {
  pv: {
    channel: string
  } // channel
  stay: {
    stay: number
  } // 页面停留时间
  // click: string // 点击元素名
  // impression: string // 曝光元素名
  api: {
    path: string // api 的 path
    body?: any // 请求体
    res?: any // 响应数据
    duration: number // 请求端对端耗时
    requestId: string // 请求唯一标识
    timestamp: number // 请求响应时间戳
  }
  // TODO:
  perf: {}
  bridge: {
    name: string
    duration?: number
    type: number
    isSuccess?: boolean
    successResult?: number
    [property: string]: any
  }
  error: {
    error: string
    extra: Object
  }
  custom: {
    message: string
    extra: Object
    metricConfigs?: MetricConfig[]
  }
  [property: string]: any
}

export interface bridgeDetailParams {
  name: string
  duration?: number
  type?: number
  isSuccess: boolean
  successResult?: number
}

export enum BridgeTypeEnum {
  total = 1, // 总调用情况
  detail,
  custom // 业务自定义上报
}

export interface ReportReqParams {
  header: CommonHeader
  message: ReportMessage
}

export interface CommonHeader {
  /**
   * 设备品牌，如 iphone / huawei
   */
  device_brand: string

  /**
   * 操作系统，如 Android / iOS
   */
  system_type: string

  /**
   * 操作系统版本
   */
  system_version: string

  /**
   * shopee app 版本
   */
  app_version: string

  rn_version: string

  /**
   * 网络类型：wifi / 4g / ...
   */
  network_type: NetworkTypeEnum | string

  /**
   * credit h5 应用周期内生成的唯一 uuid
   */
  session_id: string

  /**
   * 当前业务名。本次上报来自哪个业务？ 自定义
   */
  biz_type: string

  /**
   * 当前国家/地区名。本次上报来自哪个国家/地区？ 如： ID,TH,PH,TW,MY,SG,VN
   */
  region: string

  /**
   * 离线包版本
   */
  offline_pkg_version: string
}

export interface ReportMessage {
  cmd: string
  eventList?: EventDataType[]
  pageHistory?: PageHistory[]
}

export interface PageHistory {
  url?: string
  timestamp?: number
  channel: string
  stay: string | number
  type: string
  fromUrl?: string
}

export interface FinalRequestParams {
  headers: Headers
  body: ReportReqParams
}

export interface Headers {
  Accept: string
  'Content-Type': string
  'X-Kredit-Req-APPID': string
  'X-Kredit-Req-H': string
  'X-Kredit-Req-Source': number
  'X-Kredit-Trace-ID': string
  'X-Kredit-Start-Timestamp': string
  'X-Kredit-Req-Language': string
}

export enum CMD {
  REPORT = 'report'
}

// api上报参数
export interface ApiParamsType {
  path: string
  duration: number
  httpStatus: number
  body?: any
  res?: any
  [property: string]: any
}
export enum NetworkTypeEnum {
  'unknown' = 0,
  'slow-2g',
  '2g',
  '3g',
  '4g',
  'wifi' = 9
}

export type MetricType = 'counter' | 'histogram' | 'summary'

export interface MetricConfig {
  metricName: string
  type: MetricType,
  labels: string[],
  buckets?: number[],
  percentiles?: number[]
  desc: string
}

export interface ReportCustomMetricParams {
  metricName: string
  value?: number
}
